<template>
	<div>
		<el-dialog title="出库" :visible.sync="dialogVisible" :before-close="closeOutDialog" width="50%">
			<el-form ref="dialogForm" :model="dialogForm" :rules="rules">
				<el-form-item label="材料采购号" label-width="140px" prop="modr_no" class="vg_mb_5">
					<el-input v-model="dialogForm.modr_no" @change="modrPodrValue" placeholder="请输入材料采购号" autocomplete="off" size="small" maxlength="30"/>
				</el-form-item>
				<el-form-item label="成品采购号" label-width="140px" prop="podr_no">
					<!-- <el-input v-model="dialogForm.podr_no" placeholder="请输入成品采购号" autocomplete="off" size="small" maxlength="30"/> -->
					<el-select v-model="dialogForm.podr_no" placeholder="请选择成品采购号" size="small" filterable>
						<el-option
							v-for="item in podrList"
							:key="item"
							:label="item"
							:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="请选择出库类型" label-width="140px" prop="stck_type">
					<el-select v-model="dialogForm.stck_type" filterable placeholder="请选择库存类型" clearable size="small">
						<el-option v-for="item in stckTypeList" :key="item.value" :label="item.label" :value="item.value"/>
					</el-select>
				</el-form-item> -->
				<el-table ref="multiTable" :data="dialogForm.tableData" border>
					<el-table-column label="材料名称">
						<template v-slot="scope">
							<el-form-item label-width="0" :prop="'tableData['+scope.$index+'].mtrl_name'">
								<el-input v-model="dialogForm.tableData[scope.$index].mtrl_name" autocomplete="off" size="small" :disabled="true"/>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column label="库存数量">
						<template v-slot="scope">
							<el-form-item label-width="0" :prop="'tableData['+scope.$index+'].mtrb_stck_num'" :rules="[{required:true}]">
								<el-input v-model="dialogForm.tableData[scope.$index].mtrb_stck_num" autocomplete="off" size="small" :disabled="true"/>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column label="出库数量">
						<template v-slot="scope">
							<el-form-item label-width="0" :prop="'tableData['+scope.$index+'].stck_num'" :rules="[{required:true}]">
								<el-input :disabled="dialogForm.tableData[scope.$index].stck_num === 0" @change="stckNumChnage(scope.$index)" v-model="dialogForm.tableData[scope.$index].stck_num" autocomplete="off" size="small"/>
							</el-form-item>
						</template>
					</el-table-column>
					<!-- <el-table-column label="库位">
						<template v-slot="scope">
							<el-form-item label-width="0" :prop="'tableData['+scope.$index+'].stck_bin'" :rules="[{required:true}]">
								<el-select v-model="dialogForm.tableData[scope.$index].stck_bin" filterable placeholder="请选择库位" clearable size="small">
									<el-option v-for="item in stckBinList" :key="item.id" :label="item.param1" :value="item.param1"/>
								</el-select>
							</el-form-item>
						</template>
					</el-table-column> -->
				</el-table>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeOutDialog">取 消</el-button>
				<el-button type="primary" @click="confirm">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {get, post} from "@api/request";
import {mainAPI} from "@api/modules/main";
import {dentAPI} from "@api/modules/dent";
import {cloneDeep, debounce} from "lodash";
import {optnAPI} from "@api/modules/optn";

export default {
	name: "AccessoryOutStorageDialog",
	props: {
		dialogVisible: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			rules:{
				modr_no: [{required: true, trigger: 'blur', message: ' '},],
				podr_no: [{required: true, trigger: 'blur', message: ' '},],
				stck_type: [{required: true, trigger: 'change', message: ' '},],
			},
			stckTypeList: [
				{label: "生产制作", value: 2},
				{label: "其他", value: 3},
				{label: "翻单", value: 4},
			],
			dialogForm: {
				tableData: [],
			},
			mtrbNameList: [],
			stckBinList: [],
			podrList:[],
		}
	},
	watch: {
		'dialogForm.modr_no'(newValue, oldValue) {
			this.selectChange()
		},
		'dialogForm.podr_no'(newValue, oldValue) {
			this.selectChange()
		}
	},
	mounted() {
		this.getOptnByPermId();
	},
	methods: {
		getOptnByPermId() {
			get(optnAPI.getAllContent, {perm_id: 10006}).then(({data}) => {
				if (data.code === 0) {
					this.stckBinList = data.data
				}
			});
		},
		// 获取成品采购号
		modrPodrValue(){
			get(dentAPI.getPodrNosByModrNo, {modr_no: this.dialogForm.modr_no}).then(({data}) => {
				if (data.code === 0) {
					this.podrList = data.data.podr_no_list
				}
			});
		},
		closeOutDialog() {
			this.dialogForm = {
				mtrb_id:null,
				modr_no:null,
				tableData:[]
			}
			this.$emit("closeOutDialog", 'pr')
		},
		stckNumChnage(val){
			if(Number(this.dialogForm.tableData[val].stck_num)>Number(this.dialogForm.tableData[val].mtrb_stck_num)){
				this.dialogForm.tableData[val].stck_num = null;
				this.$message.warning('出库数量不可大于库存数量')
			}
		},
		selectChange: debounce(function () {
			if (this.dialogForm.modr_no && this.dialogForm.podr_no) {
				get(dentAPI.get_dent_mtrb_by_podr_modr_no,
						{podr_no: this.dialogForm.podr_no, modr_no: this.dialogForm.modr_no}).then(({data}) => {
					if (data.code === 0) {
						this.dialogForm.tableData = [];
						let map = new Map()
						data.data.list.forEach((item) => {
							if (map.has(item.mtrb_name)){
								map.set(item.mtrb_name,[map.get(item.mtrb_name)[0] + item.dent_mtrb_num, item.mtrb_stck_num])
							}else {
								map.set(item.mtrb_name,[item.dent_mtrb_num,item.mtrb_stck_num])
							}
						});
						map.forEach((value, key) => {
							this.dialogForm.tableData.push({
								mtrl_name: key,
								stck_num: value[1],
								mtrb_stck_num: value[1],
								stck_bin: null,
							})
						})
					}
				});
			} else {
				this.dialogForm.tableData = [];
			}
		}, 500),
		confirm() {
			let num = 0;
			this.dialogForm.tableData.forEach(item=>{
				if(item.stck_num){
					if(Number(item.stck_num)>Number(item.mtrb_stck_num)){
						num = 2
					}
				}else{
					if(item.stck_num !== 0){
						num = 1
					}
				}
			})
			if(num === 1){
				return this.$message.warning('请填写出库数量')
			}else if(num === 2){
				return this.$message.warning('出库数量不可大于库存数量')
			}else{
				console.log('');
			}
			this.$refs['dialogForm'].validate((valid) => {
				if (valid) {
					let userInfo = this.$cookies.get("userInfo");
					this.dialogForm.acct_id = userInfo.acct_id;
					this.dialogForm.user_id = userInfo.user_id;
					this.dialogForm.stff_id = userInfo.stff_id;
					this.dialogForm.dept_id = userInfo.dept_id;
					this.dialogForm.cptt_type = userInfo.cptt_type; 
					this.dialogForm.dept_team_id = userInfo.dept_team_id;
					let tableData = cloneDeep(this.dialogForm.tableData);
					tableData.forEach((item) =>{
						item = Object.assign(item, this.dialogForm);
					})
					post(mainAPI.stckOutByProcFact, tableData).then(({data}) => {
						if (data.code === 0) {
							this.$message.success("出库成功!");
							this.closeOutDialog();
						}
					});
				} else {
					console.log('error submit!');
					return false;
				}
			});
		},
	},
	
}
</script>

<style scoped>

</style>