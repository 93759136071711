<template>
	<div>
		<el-dialog title="入库" :visible.sync="dialogVisible" :before-close="closePutDialog" width="50%">
			<el-form ref="dialogForm" :model="dialogForm" :rules="rules">
				<el-form-item label="材料采购号" label-width="140px" prop="modr_no" class="vg_mb_5">
					<el-input v-model="dialogForm.modr_no" @change="modrPodrValue" placeholder="请输入材料采购号" autocomplete="off" size="small" maxlength="30"/>
				</el-form-item>
				<el-form-item label="成品采购号" label-width="140px" prop="podr_no">
					<!-- <el-input v-model="dialogForm.podr_no" placeholder="请输入成品采购号" autocomplete="off" size="small" maxlength="30"/> -->
					<el-select v-model="dialogForm.podr_no" placeholder="请选择成品采购号" size="small" filterable>
						<el-option
							v-for="item in podrList"
							:key="item"
							:label="item"
							:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="请选择入库类型" label-width="140px" prop="stck_type">
					<el-select v-model="dialogForm.stck_type" filterable placeholder="请选择库存类型" clearable size="small">
						<el-option v-for="item in stckTypeList" :key="item.value" :label="item.label" :value="item.value"/>
					</el-select>
				</el-form-item> -->
				<el-table ref="multiTable" :data="dialogForm.tableData" border>
					<el-table-column label="材料名称">
						<template v-slot="scope">
							<el-form-item label-width="0" :prop="'tableData['+scope.$index+'].mtrl_name'">
								<el-input v-model="dialogForm.tableData[scope.$index].mtrl_name" placeholder="请输入" autocomplete="off" size="small" :disabled="true"/>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column label="数量">
						<template v-slot="scope">
							<el-form-item label-width="0" :prop="'tableData['+scope.$index+'].stck_num'" :rules="[{required:true}]">
								<el-input v-model="dialogForm.tableData[scope.$index].stck_num" autocomplete="off" size="small"/>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column label="库位">
						<template v-slot="scope">
							<el-form-item label-width="0" :prop="'tableData['+scope.$index+'].stck_bin'" :rules="[{required:true}]">
								<el-select v-model="dialogForm.tableData[scope.$index].stck_bin" filterable placeholder="请选择库位" clearable size="small">
									<el-option v-for="item in stckBinList" :key="item.id" :label="item.param1" :value="item.param1"/>
								</el-select>
							</el-form-item>
						</template>
					</el-table-column>
				</el-table>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closePutDialog">取 消</el-button>
				<el-button type="primary" @click="confirm">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {get, post} from "@api/request";
import {mainAPI} from "@api/modules/main";
import {dentAPI} from "@api/modules/dent";
import {cloneDeep, debounce, throttle} from "lodash";
import {optnAPI} from "@api/modules/optn";

export default {
	name: "AccessoryPutStorageDialog",
	props: {
		dialogVisible: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			rules: {
				modr_no: [{required: true, trigger: 'blur', message: ' '},],
				podr_no: [{required: true, trigger: 'blur', message: ' '},],
				stck_type: [{required: true, trigger: 'change', message: ' '},],
			},
			dialogForm: {
				tableData: [],
			},
			stckTypeList: [
				{label: "工厂库存", value: 0},
				{label: "订单入库", value: 1},
				{label: "其他", value: 3},
			],
			stckBinList: [],
			podrList:[],
		};
	},
	watch: {
		'dialogForm.modr_no'(newValue, oldValue) {
			this.selectChange()
		},
		'dialogForm.podr_no'(newValue, oldValue) {
			this.selectChange()
		}
	},
	mounted() {
		this.getOptnByPermId()
	},
	methods: {
		getOptnByPermId() {
			get(optnAPI.getAllContent, {perm_id: 10006}).then(({data}) => {
				if (data.code === 0) {
					this.stckBinList = data.data
				}
			});
		},
		closePutDialog() {
			this.dialogForm = {
				mtrb_id:null,
				modr_no:null,
				tableData:[]
			}
			this.$emit("closePutDialog",'pr')
		},
		// 获取成品采购号
		modrPodrValue(){
			get(dentAPI.getPodrNosByModrNo, {modr_no: this.dialogForm.modr_no}).then(({data}) => {
				if (data.code === 0) {
					this.podrList = data.data.podr_no_list
				}
			});
		},
		selectChange:debounce(function () {
			if (this.dialogForm.modr_no && this.dialogForm.podr_no) {
					get(dentAPI.get_dent_mtrb_by_podr_modr_no,
							{podr_no: this.dialogForm.podr_no, modr_no: this.dialogForm.modr_no}).then(({data}) => {
						if (data.code === 0) {
							let map = new Map()
							data.data.list.forEach((item) => {
								if (map.has(item.mtrb_name)){
									map.set(item.mtrb_name,map.get(item.mtrb_name) + item.dent_mtrb_num)
								}else {
									map.set(item.mtrb_name,item.dent_mtrb_num)
								}
							});
							map.forEach((value,key) => {
								this.dialogForm.tableData.push({
									mtrl_name: key,
									stck_num: value,
									stck_bin: null,
								})
							})
						}
					});
			} else {
				this.dialogForm.tableData = [];
			}
		},500),
		confirm() {
			console.warn(this.dialogForm)
			this.$refs['dialogForm'].validate((valid) => {
				if (valid) {
					let userInfo = this.$cookies.get("userInfo");
					this.dialogForm.acct_id = userInfo.acct_id;
					this.dialogForm.user_id = userInfo.user_id;
					this.dialogForm.stff_id = userInfo.stff_id;
					this.dialogForm.dept_id = userInfo.dept_id;
					this.dialogForm.cptt_type = userInfo.cptt_type; 
					this.dialogForm.dept_team_id = userInfo.dept_team_id;
					let tableData = cloneDeep(this.dialogForm.tableData);
					tableData.forEach((item) =>{
						item = Object.assign(item, this.dialogForm);
					})
					post(mainAPI.stckInByProcFact, tableData).then(({data}) => {
						if (data.code === 0) {
							this.$message.success("入库成功!");
							this.closePutDialog();
						}
					});
				} else {
					console.log('error submit!');
					return false;
				}
			});
		},
	},
}
</script>

<style scoped>

</style>