<template>
  <div>
    <el-row class="vd_dis_cen vg_mb_16">
      {{mrtlName}}占用库存详情
    </el-row>
    <el-row>
      <el-table ref="multiTable" :data="tableData" border  v-loading="loadingFlag">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column label="更新日期" prop="create_time" :formatter="formatDate"/>
        <el-table-column label="收货方简称" prop="supp_abbr"/>
        <el-table-column label="占用材料采购号" prop="modr_no">
        </el-table-column>
        <el-table-column label="占用成品采购号" prop="podr_no">
        </el-table-column>
        <el-table-column label="占用数量" prop="stck_occu_num">
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template v-slot="scope">
            <el-tag type="success" size="mini" v-if="scope.row.delete_time === 0">未发货</el-tag>
            <el-tag type="danger" size="mini" v-else>已发货</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {get, post} from "@api/request";
import {mainAPI} from "@api/modules/main";
import pubPagination from "@/components/common/pubPagination";
export default {
  name:'OccupyRecord',
	components: {
		pubPagination,
	},
  props:{
    mrtlName:{type:String}
  },
  data(){
    return{
      tableData:[],
			totalPage: 0,
      pageNo:1,
      loadingFlag:true,
      stckId:0,
    }
  },
  created(){
  },
  methods:{
		// 时间转换
		formatDate({create_time}) {
			return this.helper.toStringDay(create_time)
		},
		// 分页查询
		changePageSearch(val) {
			this.pageNo = val;
      this.getList(this.stckId)
		},
    // 获取页面数据
		getList(val) {
      this.loadingFlag = true;
      this.stckId = val
			get(mainAPI.getStckOccusByStckId, {
        page_no:this.pageNo,
        stck_id:val
      }).then(res => {
				if (res.data.code === 0) {
					this.tableData = res.data.data.list;
					this.totalPage = res.data.data.total;
					setTimeout(() => {
						this.loadingFlag = false;
					}, 500);
				}else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
			});
		},
  }
}
</script>

<style lang="scss" scoped>
.vd_dis_cen{
  display: flex;
  justify-content: center;
}
.vd_dis_r{
  display: flex;
  justify-content: flex-end;
}
</style>