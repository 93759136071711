<template>
	<div class="vg_wrapper">
		<el-card>
			<div class="vd_search_group">
				<el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getDataLatest()" label-width="120px">
					<el-row>
						<el-col :md="8">
							<el-form-item label="名称:">
								<el-input size="small" v-model.trim="searchForm.mtrb_name" clearable placeholder="请填写名称"/>
							</el-form-item>
						</el-col>
						<el-col :md="8">
							<el-form-item label="类型:">
								<el-select v-model="searchForm.mtrl_type" filterable placeholder="请选择类型" clearable size="small">
									<el-option v-for="item in mtrlTypeList" :key="item.value" :label="item.label" :value="item.value"/>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :md="8">
							<el-form-item label="库位:">
								<el-select v-model="searchForm.stck_bin" filterable placeholder="请选择库位" clearable size="small">
									<el-option v-for="item in stckBinList" :key="item.id" :label="item.param1" :value="item.param1"/>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :md="8">
							<el-form-item label="*所属账套:" v-if="this.$cookies.get('userInfo').acct_id === 1">
								<el-select v-model="searchForm.acct_id" filterable placeholder="请选择所属账套" clearable size="small">
									<el-option v-for="item in acctList" :key="item.acct_id" :label="item.acct_no" :value="item.acct_id"/>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :md="12">
							<el-form-item label="状态:">
								<el-select v-model="searchForm.delete_status" filterable placeholder="请选择状态" clearable size="small">
									<el-option v-for="item in deleteStatusList" :key="item.id" :label="item.label" :value="item.id"/>
								</el-select>
							</el-form-item>
              <el-form-item style="margin-left: 10px" v-if="this.$cookies.get('userInfo').acct_id !== 1">
								<el-button type="primary" size="small" icon="el-icon-search" @click="getDataLatest()">查询</el-button>
								<el-button type="info" size="small" icon="el-icon-refresh" @click="buttonRefresh">刷新</el-button>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :md="12">
							<el-form-item label="*工厂类型:" v-if="this.$cookies.get('userInfo').acct_id === 1">
								<el-select v-model="searchForm.acct_id" filterable placeholder="请选择工厂类型" clearable size="small">
									<el-option v-for="item in acctList" :key="item.acct_id" :label="item.acct_no" :value="item.acct_id"/>
								</el-select>
							</el-form-item>
							<el-form-item style="margin-left: 10px" v-if="this.$cookies.get('userInfo').acct_id === 1">
								<el-button type="primary" size="small" icon="el-icon-search" @click="getDataLatest()">查询</el-button>
								<el-button type="info" size="small" icon="el-icon-refresh" @click="buttonRefresh">刷新</el-button>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin-bottom: 8px">
						<el-col :md="20">
							<div style="display: flex">
								<el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="userInfo.cptt_type !== 1 && userInfo.acct_id !== 1"
                @click="showPutDialog('mp')">入库</el-button>
								<!-- v-if="userInfo.cptt_type !== 1 && userInfo.acct_id !== 1" -->
								<el-button type="success" icon="el-icon-remove-outline" size="small" v-if="userInfo.cptt_type !== 1 && userInfo.acct_id !== 1"
                @click="showOutDialog('mp')">出库</el-button>
								<!-- v-if="userInfo.cptt_type !== 1 && userInfo.acct_id !== 1" -->
								<el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="userInfo.cptt_type === 1 && userInfo.acct_id !== 1"
                @click="showPutDialog('pr')">入库</el-button>
								<!-- v-if="userInfo.cptt_type === 1 && userInfo.acct_id !== 1" -->
								<el-button type="success" icon="el-icon-remove-outline" size="small" v-if="userInfo.cptt_type === 1 && userInfo.acct_id !== 1"
                @click="showOutDialog('pr')">出库</el-button>
								<!-- v-if="userInfo.cptt_type === 1 && userInfo.acct_id !== 1" -->
								<!-- <el-button v-if="userInfo.acct_id !== 1" size="small" type="info" icon="el-icon-refresh" @click="deleteById">更换库位</el-button> -->
								<el-button v-if="userInfo.acct_id === 1" size="small" type="danger" icon="el-icon-delete" @click="deleteById">作废</el-button>
							</div>
						</el-col>
            <el-col :md="4" v-if="userInfo.acct_id === 1">
              <template>
                <el-radio @input="changeFactType" v-model="radio" label="1">加工厂</el-radio>
                <el-radio @input="changeFactType" v-model="radio" label="2">配套厂</el-radio>
              </template>
            </el-col>
					</el-row>
				</el-form>
			</div>
			<el-row>
				<el-col :md="24">
          <div v-if="radio === '1'">
            <ProcessFactory ref="ProcessFactory" @OnekeyWarehous="OnekeyWarehous" @wrongChangePage="changePageSearch1" :totalPage="totalPage1" :tableData="tableData1" :loadingFlag="loadingFlag" :userInfo="userInfo" @selectionChange="selectionChange"></ProcessFactory>
          </div>
          <div v-if="radio === '2'">
            <WrongProcessFactory @OnekeyWarehous="OnekeyWarehous" ref="WrongProcessFactory" @wrongChangePage="changePageSearch2" :totalPage="totalPage2" :tableData="tableData2" :loadingFlag="loadingFlag" :userInfo="userInfo" @wrongSelectionChange="wrongSelectionChange"></WrongProcessFactory>
          </div>
				</el-col>
			</el-row>
		</el-card>
		<AccessoryPutStorageDialog :dialogVisible="mpPutDialogVisible" @closePutDialog="closePutDialog"/>
		<AccessoryOutStorageDialog :dialogVisible="mpOutDialogVisible" @closeOutDialog="closeOutDialog"/>
		<AccessoryPutStorageDialog1 :dialogVisible="prPutDialogVisible" @closePutDialog="closePutDialog"/>
		<AccessoryOutStorageDialog1 :dialogVisible="prOutDialogVisible" @closeOutDialog="closeOutDialog"/>
	</div>
</template>

<script>
import {get, post} from "@api/request";
import {mstfAPI} from "@api/modules/mstf";
import {optnAPI} from "@api/modules/optn";
import {acctAPI} from "@api/modules/acct";
import {mainAPI} from "@api/modules/main";
import helper from "@assets/js/helper";
import {factAPI} from "@api/modules/fact";
import AccessoryPutStorageDialog from "@/views/StockManagement/MainManage/Dialog/AccessoryPutStorageDialog";
import AccessoryOutStorageDialog from "@/views/StockManagement/MainManage/Dialog/AccessoryOutStorageDialog";
import AccessoryPutStorageDialog1 from "@/views/StockManagement/MainManage/Dialog/AccessoryPutStorageDialog1";
import AccessoryOutStorageDialog1 from "@/views/StockManagement/MainManage/Dialog/AccessoryOutStorageDialog1";
import ProcessFactory from "./tableList/ProcessFactory.vue"
import WrongProcessFactory from "./tableList/WrongProcessFactory.vue"

export default {
	name: "MainList",
	components: {
		AccessoryOutStorageDialog,
		AccessoryPutStorageDialog,
		AccessoryOutStorageDialog1,
		AccessoryPutStorageDialog1,
    ProcessFactory,
    WrongProcessFactory,
	},
	data() {
		return {
			mpPutDialogVisible: false,
			mpOutDialogVisible: false,
			prPutDialogVisible: false,
			prOutDialogVisible: false,
			tableData1: [],//加工厂·
			tableData2: [],//非加工厂·，配套厂
			searchForm: {
				page_no: 1,
			},
			pageNoO:1,
			pageNoT:1,
			totalPage1: 0,
			totalPage2: 0,
			btn: {},
			loadingFlag: true,
			mtrlTypeList: [
				{label: "材辅", value: 0},
				{label: "包材", value: 1},
				{label: "成品", value: 2},
				{label: "面料", value: 3},
				{label: "外发半成品", value: 4},
				{label: "皮壳", value: 5},
			],
			deleteStatusList:[
				{id:0,label:'正常'},
				{id:1,label:'作废'},
			],
			stckBinList: [],
			acctList: [],
			selectionList: [],
			userInfo: {},
      radio:'2'
		};
	},
	created() {
		this.userInfo = this.$cookies.get('userInfo')
    if(this.userInfo.acct_id === 1){
      this.radio = '1'
    }else{
      if(this.userInfo.cptt_type === 1){
        this.radio = '1'
      }else{
        this.radio = '2'
      }
    }
		this.initData();
		this.getOptnByPermId()
	},
	watch: {
	},
	methods: {
		initData() {
			this.loadingFlag = true
			if(this.userInfo.acct_id === 1){
				this.getAcct()
			}
			this.getDataList()
		},
    // 获取账套
		getAcct() {
			get(acctAPI.getAllAcctsV1, {}).then(({data}) => {
				if (data.code === 0) {
					this.acctList = data.data;
				}
			});
		},
    // 获取库位
		getOptnByPermId() {
			get(optnAPI.getAllContent, {perm_id: 10006}).then(({data}) => {
				if (data.code === 0) {
					// this.stckBinList = data.data
					let list = data.data;
					let stckBinListcopy = [...new Set(list.map(i=>i.param1))]
					stckBinListcopy.forEach((item,index)=>{
						let temp = {}
						temp.id = index;
						temp.param1 = item
						this.stckBinList.push(temp)
					})
				}
			});
		},
    // 获取页面数据
		getDataList() {
			if(this.radio === '2'){
				this.getWrongProcessFactory()
			}else if(this.radio === '1'){
				this.getProcessFactory()
			}
		},
		// 获取加工厂数据
		getProcessFactory(){
			let searchForm = JSON.parse(JSON.stringify(this.searchForm))
			searchForm.page_no = this.pageNoT;
			searchForm.stck_type = 1;
			get(mainAPI.getStcks, searchForm).then(({data}) => {
				if (data.code === 0) {
					this.tableData1 = data.data.list;
					this.totalPage1 = data.data.total;
					setTimeout(() => {
						this.loadingFlag = false;
					}, 500);
				}
			});
		},
		// 获取非加工厂数据
		getWrongProcessFactory(){
			let searchForm = JSON.parse(JSON.stringify(this.searchForm))
			searchForm.page_no = this.pageNoT;
			searchForm.stck_type = 0;
			get(mainAPI.getStcks, searchForm).then(({data}) => {
				if (data.code === 0) {
					this.tableData2 = data.data.list;
					this.totalPage2 = data.data.total;
					setTimeout(() => {
						this.loadingFlag = false;
					}, 500);
				}
			});
		},
		// 刷新
		buttonRefresh() {
			this.searchForm = {page_no: 1};
			if(this.radio === '2'){
				this.pageNoT = 1
				this.$refs.WrongProcessFactory.empty()
			}else if(this.radio === '1'){
				this.pageNoO = 1
				this.$refs.ProcessFactory.empty()
			}
			this.loadingFlag = true;
			this.initData();
		},
		// 查询方法
		getDataLatest() {
			this.loadingFlag = true;
			this.initData();
			if(this.radio === '2'){
				this.$refs.WrongProcessFactory.empty()
			}else if(this.radio === '1'){
				this.$refs.ProcessFactory.empty()
			}
		},
		// 分页查询非加工厂
		changePageSearch2(val) {
			this.pageNoT = val;
			this.initData();
		},
		// 分页查询加工厂
		changePageSearch1(val) {
			this.pageNoO = val;
			this.initData();
		},
    // 入库
		showPutDialog(factoryType) {
			switch (factoryType) {
				case 'mp':
					this.mpPutDialogVisible = true;
					break;
				case 'pr':
					this.prPutDialogVisible = true;
			}
		},
    // 出库
		showOutDialog(factoryType) {
			switch (factoryType) {
				case 'mp':
					this.mpOutDialogVisible = true;
					break;
				case 'pr':
					this.prOutDialogVisible = true;
					break
			}
		},
    // 入库关闭弹框
		closePutDialog(factoryType) {
			switch (factoryType) {
				case 'mp':
					this.mpPutDialogVisible = false;
					this.initData();
					break;
				case 'pr':
					this.prPutDialogVisible = false;
					this.initData();
					break
			}
		},
    // 出库关闭弹框
		closeOutDialog(factoryType) {
			switch (factoryType) {
				case 'mp':
					this.mpOutDialogVisible = false
					this.initData();
					break
				case 'pr':
					this.prOutDialogVisible = false;
					this.initData();
					break
			}
		},
    // 加工厂勾选值确认值
		selectionChange(val) {
			this.selectionList = val;
		},
    // 配套厂勾选值确认值
		wrongSelectionChange(val) {
			this.selectionList = val;
		},
    // 更换工厂
    changeFactType(){
			this.initData();
    },
		// 刷新页面
		OnekeyWarehous(){
			this.initData();
		},
    // 作废
		deleteById() {
			if(this.selectionList.length === 0) return this.$message.warning('至少选择一条数据')
			let ids = this.selectionList.map(({stck_id}) => (Object.values({stck_id}).toString()))
			post(mainAPI.deleteStck, {stck_id_list: ids}).then(({data}) => {
				if (data.code === 0) {
					this.initData();
				}
			});
		},
	},
};
</script>

<style scoped lang="scss">
</style>
<style>
</style>
