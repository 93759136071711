<template>
  <div>
    <el-row class="vd_dis_cen vg_mb_16">
      {{mrtlName}}出入库记录
    </el-row>
    <el-row class="vd_dis_r vg_mb_16">
      最新结余库存{{tableData.length>0?tableData[0].stfw_surp:0}}个<span v-if="factType === 0">;库存资产{{totalPrice}}元</span>
    </el-row>
    <el-row>
      <el-table ref="multiTable" :data="tableData" border  v-loading="loadingFlag">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column label="更新日期" prop="create_time" :formatter="formatDate"/>
        <el-table-column label="入库数量" prop="stfw_num" :formatter="formatIn"/>
        <el-table-column label="出库数量" prop="stfw_num" :formatter="formatOut"/>
        <el-table-column label="结余库存" prop="stfw_surp" show-overflow-tooltip/>
        <el-table-column label="相关材料采购号" prop="modr_no">
        </el-table-column>
        <el-table-column label="相关成品采购号" prop="podr_no">
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {get, post} from "@api/request";
import {mainAPI} from "@api/modules/main";
import { BigNumber } from "bignumber.js";
import pubPagination from "@/components/common/pubPagination";
export default {
  name:'NameRecord',
	components: {
		pubPagination,
	},
  props:{
    mrtlName:{type:String},
    AveragePrice:{type:Number,default:0},
    factType:{type:Number},
  },
  data(){
    return{
      tableData:[],
			totalPage: 0,
      pageNo:1,
      loadingFlag:true,
      stckId:0,
      totalPrice:0
    }
  },
  created(){
  },
  methods:{
		// 时间转换
		formatDate({create_time}) {
			return this.helper.toStringDay(create_time)
		},
		// 分页查询
		changePageSearch(val) {
			this.pageNo = val;
      this.getList(this.stckId)
		},
    // 入库回显
    formatIn(row){
      if(row.stfw_type === 0){
        return row.stfw_num
      }else{
        return 0
      }
    },
    // 出库回显
    formatOut(row){
      if(row.stfw_type === 1){
        return row.stfw_num
      }else{
        return 0
      }
    },
    // 获取页面数据
		getList(val) {
      this.loadingFlag = true;
      this.stckId = val
			get(mainAPI.getStfws, {
        page_no:this.pageNo,
        stck_id:val
      }).then(res => {
				if (res.data.code === 0) {
					this.tableData = res.data.data.list;
					this.totalPage = res.data.data.total;
          if(this.tableData.length>0){
            let price = new BigNumber(this.AveragePrice?this.AveragePrice:0)
            price = price.times(this.tableData[0].stfw_surp).toNumber()
            this.totalPrice = price
          }
					setTimeout(() => {
						this.loadingFlag = false;
					}, 500);
				}else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
			});
		},
  }
}
</script>

<style lang="scss" scoped>
.vd_dis_cen{
  display: flex;
  justify-content: center;
}
.vd_dis_r{
  display: flex;
  justify-content: flex-end;
}
</style>