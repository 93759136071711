<template>
  <div>
    <el-dialog
    :visible.sync="WareHouseFlag"
    :before-close="handleClose"
    width="50%">
      <el-row class="vd_dis_cen vg_mb_16">
        {{mrtlName}}库位详情
      </el-row>
      <el-row class="vg_mb_8">
        <el-col :md="12" v-if="factType === 1">
          <el-button :disabled="userInfo.acct_id === 1" type="primary" size="mini" plain @click="addData">添加</el-button>
          <el-button :disabled="userInfo.acct_id === 1" type="danger" size="mini" plain @click="deleteData">删除</el-button>
        </el-col>
        <el-col :md="factType === 1?12:24" class="vd_dis_r">
          库存总数：{{WareHouseNum}}
        </el-col>
      </el-row>
      <el-row class="vd_dis_cen vg_mb_16">
        <el-table ref="multiTable" :data="tableData" border v-loading="loadingFlag" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center"></el-table-column>
          <el-table-column label="库位" prop="stck_bin_name">
            <template v-slot="scope">
              <el-select :disabled="userInfo.acct_id === 1" v-model="scope.row.stck_bin_name" filterable placeholder="请选择库位" size="small">
                    <el-option v-for="item in stckBinList" :key="item.id" :label="item.param1" :value="item.param1"/>
                  </el-select>
            </template>
          </el-table-column>
          <el-table-column label="库存数量" prop="stck_bin_num">
            <template v-slot="scope">
              <el-input-number :disabled="userInfo.acct_id === 1" style="width:100%" v-model="scope.row.stck_bin_num"  placeholder="请输入库存数量"></el-input-number>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <el-col :md="24" class="vd_dis_r">
          <el-button :disabled="userInfo.acct_id === 1" type="primary" size="small" @click="submit">保存</el-button>
        </el-col>
      </el-row>
		</el-dialog>
  </div>
</template>

<script>
import {get, post} from "@api/request";
import {mainAPI} from "@api/modules/main";
import {optnAPI} from "@api/modules/optn";
export default {
  name:'WareHouse',
	components: {
	},
  props:{
    mrtlName:{type:String},
    WareHouseNum:{type:Number},
    factType:{type:Number},
    WareHouseFlag:{type:Boolean},
  },
  data(){
    return{
      tableData:[],
      tableDataCopy:[],
      stckBinList:[],
      loadingFlag:true,
      stckId:0,
      selectionList:[],
      userInfo:null,
    }
  },
  created(){
    this.userInfo = this.$cookies.get('userInfo')
    this.getOptnByPermId()
  },
  methods:{
    // 添加数据
    addData(){
      let totalNum = Number(JSON.parse(JSON.stringify(this.WareHouseNum)));
      this.tableData.forEach(item=>{
        totalNum = totalNum - item.stck_bin_num
      })
      let item = {
        stck_bin_name:'',
        stck_bin_num:totalNum>=0?totalNum:0,
        stck_id:this.stckId,
        key:this.tableData.length
      }
      this.tableData.push(item)
    },
    // 删除数据
    deleteData(){
      if(this.selectionList.length === 0) return this.$message.warning('至少选择一条数据')
      let temp = [];
      this.selectionList.forEach(item=>temp.push(item.key))
      temp = temp.sort((a,b)=>{return b-a})
      temp.forEach(item=>{
        this.tableData.splice(item,1)
      })
      this.tableData.forEach((item,index)=>{
        item.key = Number(index)
      })
    },
    // 勾选值确认值
		handleSelectionChange(val) {
			this.selectionList = val;
		},
    // 保存
    submit(){
      let totalNum = 0
      this.tableData.forEach(item=>totalNum+=item.stck_bin_num)
      if(totalNum !== this.WareHouseNum) return this.$message.warning('各库位的库存数量相加必须等于库存总数')
      if(this.factType === 1){
        this.tableDataCopy = this.tableDataCopy.filter(item => this.tableData.every(item1 => item.stck_bin_id !== item1.stck_bin_id));
        for (let i in this.tableDataCopy) {
          this.tableDataCopy[i].destroy_flag = 1
        }
        this.tableData = this.tableData.concat(this.tableDataCopy)
      }
      post(mainAPI.editStckBin, this.tableData).then((res) => {
          if (res.data.code === 0) {
            this.$message.success('成功')
            this.$emit('cancelWareHouse',1)
          }else{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          }
        });
    },
    // 关闭确认
    handleClose(done) {
      if(this.userInfo.acct_id === 1){
        this.$emit('cancelWareHouse',0)
      } else {
        this.$confirm('还未保存，是否关闭？')
        .then(_ => {
          this.$emit('cancelWareHouse',0)
        })
        .catch(_ => {
        });
      }
      
    },
    // 获取库位
		getOptnByPermId() {
			get(optnAPI.getAllContent, {perm_id: 10006}).then(({data}) => {
				if (data.code === 0) {
					this.stckBinList = data.data
				}
			});
		},
    // 获取页面数据
		getList(val) {
      this.loadingFlag = true;
      this.stckId = val
			get(mainAPI.getStckBinByStckId, {
        stck_id:val
      }).then(res => {
				if (res.data.code === 0) {
					this.tableData = res.data.data.list;
          if(this.factType === 1){
            this.tableDataCopy = JSON.parse(JSON.stringify(res.data.data.list))
          }
          this.tableData.forEach((item,index)=>{
            item.key = Number(index)
          })
					setTimeout(() => {
						this.loadingFlag = false;
					}, 500);
				}else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
			});
		},
  }
}
</script>

<style lang="scss" scoped>
.vd_dis_cen{
  display: flex;
  justify-content: center;
}
.vd_dis_r{
  display: flex;
  justify-content: flex-end;
}
</style>