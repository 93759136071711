<template>
  <div>
    <el-row class="vd_dis_cen vg_mb_16">
      {{mrtlName}}需制库存详情
    </el-row>
    <el-row class="vg_mb_8">
      <!-- <el-button @click="OnekeyWarehous" size="mini" type="primary" v-if="userInfo.acct_id !== 1">一键入库</el-button> -->
    </el-row>
    <el-row>
      <el-table ref="multiTable" :data="tableData" border  v-loading="loadingFlag" @selection-change="handleSelectionChange">
        <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
        <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
        <el-table-column label="更新日期" prop="create_time" :formatter="formatDate"/>
        <el-table-column label="收货方简称" prop="supp_abbr"/>
        <el-table-column label="需制材料采购号" prop="modr_no">
        </el-table-column>
        <el-table-column label="需制成品采购号" prop="podr_no">
        </el-table-column>
        <el-table-column label="需制数量" prop="stck_make_num">
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template v-slot="scope">
            <el-tag type="success" size="mini" v-if="scope.row.delete_time === 0">未入库</el-tag>
            <el-tag type="danger" size="mini" v-else>已入库</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {get, post} from "@api/request";
import {mainAPI} from "@api/modules/main";
import pubPagination from "@/components/common/pubPagination";
export default {
  name:'NeedRecord',
	components: {
		pubPagination,
	},
  props:{
    mrtlName:{type:String}
  },
  data(){
    return{
      tableData:[],
			totalPage: 0,
      pageNo:1,
      loadingFlag:true,
      stckId:0,
      selectionList:[],
      userInfo:{}
    }
  },
  created(){
    this.userInfo = this.$cookies.get('userInfo')
  },
  methods:{
    // 勾选值确认值
		handleSelectionChange(val) {
			this.selectionList = val;
		},
		// 时间转换
		formatDate({create_time}) {
			return this.helper.toStringDay(create_time)
		},
		// 分页查询
		changePageSearch(val) {
			this.pageNo = val;
      this.getList(this.stckId)
		},
    // 一键入库
    OnekeyWarehous(){
      let flag = false;
      this.selectionList.forEach(item => {
        if(item.delete_time > 0){
          flag = true;
        }
      });
      if(flag) return this.$message.warning('不可重复入库！')
      if(this.selectionList.length>0){
        post(mainAPI.stckInByMake, this.selectionList).then((res) => {
          if (res.data.code === 0) {
            this.$message.success('入库成功')
            this.getList(this.stckId)
            this.$emit('OnekeyWarehous')
          }else{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          }
        });
      }else{
        this.$message.warning('至少选择一条数据')
      }
    },
    // 获取页面数据
		getList(val) {
      this.loadingFlag = true;
      this.stckId = val
			get(mainAPI.getStckMakesByStckId, {
        page_no:this.pageNo,
        stck_id:val
      }).then(res => {
				if (res.data.code === 0) {
					this.tableData = res.data.data.list;
					this.totalPage = res.data.data.total;
					setTimeout(() => {
						this.loadingFlag = false;
					}, 500);
				}else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
			});
		},
  }
}
</script>

<style lang="scss" scoped>
.vd_dis_cen{
  display: flex;
  justify-content: center;
}
.vd_dis_r{
  display: flex;
  justify-content: flex-end;
}
</style>