<template>
	<div>
		<el-dialog title="出库" :visible.sync="dialogVisible" :before-close="closeOutDialog" width="30%">
			<el-form ref="dialogForm" :model="dialogForm" :rules="rules">
				<el-form-item label="名称" label-width="140px" prop="mtrb_id">
					<!-- <el-input v-model="dialogForm.mtrl_name" placeholder="请输入名称" autocomplete="off" size="small" maxlength="30"/> -->
					<el-select size="small" v-model="dialogForm.mtrb_id" placeholder="请选择名称" filterable :filter-method="fileList" @change="mtrbChange" @visible-change="mtrbVisibleChange">
						<el-option
							v-for="item in mtrlNameList"
							:key="item.mtrb_id"
							:label="item.mtrb_name"
							:value="item.mtrb_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="材料采购号" label-width="140px" prop="modr_no" class="vg_mb_5">
					<el-input v-model="dialogForm.modr_no" placeholder="请输入材料采购号" autocomplete="off" size="small" maxlength="30"/>
				</el-form-item>
				<el-form-item label="成品采购号" label-width="140px" prop="podr_no">
					<el-input v-model="dialogForm.podr_no" placeholder="请输入成品采购号" autocomplete="off" size="small" maxlength="30"/>
				</el-form-item>
				<!-- <el-form-item label="出库类型" label-width="140px" prop="stck_type">
					<el-select v-model="dialogForm.stck_type" filterable placeholder="请选择库存类型" clearable size="small">
						<el-option v-for="item in stckTypeList" :key="item.value" :label="item.label" :value="item.value"/>
					</el-select>
				</el-form-item> -->
				<el-form-item label="出库数量" label-width="140px" prop="stck_num">
					<el-input v-model="dialogForm.stck_num" placeholder="请输入出库数量" autocomplete="off" size="small"></el-input>
				</el-form-item>
				<!-- <el-form-item label="请输出库原因" label-width="120px" prop="reason">
					<el-input v-model="dialogForm.reason" autocomplete="off" type="textarea" size="small" :rows="3"></el-input>
				</el-form-item> -->
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeOutDialog">取 消</el-button>
				<el-button type="primary" @click="confirm">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {get, post} from "@api/request";
import {mainAPI} from "@api/modules/main";
import {mtrbAPI} from "@api/modules/mtrb";
import {debounce, throttle} from "lodash";

export default {
	name: "AccessoryOutStorageDialog",
	props: {
		dialogVisible: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			rules:{
				modr_no: [{required: true, trigger: 'blur', message: ' '},],
				podr_no: [{required: true, trigger: 'blur', message: ' '},],
				mtrb_id: [{required: true, trigger: 'blur', message: ' '},],
				stck_num: [{required: true, trigger: 'blur', message: ' '},],
			},
			stckTypeList: [
				{label: "订单入库", value: 1},
				{label: "其他", value: 3},
			],
			dialogForm: {},
			mtrlNameList: [],
			mtrlType:0,
		}
	},
	created(){
		this.getAllMtrbsV2()
	},
	methods: {
		closeOutDialog() {
			this.dialogForm = {
				mtrb_id:null,
				modr_no:null,
				podr_no:null,
				stck_num:null,
			}
			this.$emit("closeOutDialog",'mp')
		},
		fileList(e){
			this.getApprove(e)
		},
		getApprove:debounce(function (e) {
				this.getAllMtrbsV2(e)
			},500),
		mtrbVisibleChange(e){
			if(!e){
				this.getAllMtrbsV2()
			}
		},
		// 获取物料类型和名称
		mtrbChange(val){
			this.mtrlNameList.forEach(item=>{
				if(item.mtrb_id === val){
					this.dialogForm.mtrl_name = item.mtrb_name;
					if(item.mtrb_type>0){
						this.mtrlType = item.mtrb_type - 1;
					}else{
						this.mtrlType = 0;
					}
				}
			})
		},
		// 获取所有名称
		getAllMtrbsV2(val) {
				get(mtrbAPI.getAllMtrbsV2,{mtrb_name:val}).then(({data}) => {
					if (data.code === 0) {
						this.mtrlNameList = data.data.list
					}
				});
		},
		confirm() {
			this.$refs['dialogForm'].validate((valid) => {
				if (valid) {
					let userInfo = this.$cookies.get("userInfo");
					this.dialogForm.acct_id = userInfo.acct_id;
					this.dialogForm.user_id = userInfo.user_id;
					this.dialogForm.stff_id = userInfo.stff_id;
					this.dialogForm.dept_id = userInfo.dept_id;
					this.dialogForm.dept_team_id = userInfo.dept_team_id;
					this.dialogForm.cptt_type = userInfo.cptt_type; 
					this.dialogForm.mtrl_type = this.mtrlType; 
					post(mainAPI.stctOut, this.dialogForm).then(({data}) => {
						if (data.code === 0) {
							this.$message.success("出库成功!");
							this.closeOutDialog();
						}
					});
				} else {
					console.log('error submit!');
					return false;
				}
			});
		},
	},
}
</script>

<style scoped>

</style>