import topic from '../topic'
export const mainAPI = {
    getStcks: topic.mainTopic + '/get_stcks',
    stctIn: topic.mainTopic + '/stck_in',
    stctOut: topic.mainTopic + '/stck_out',
    getStfws: topic.mainTopic + '/get_stfws',
    deleteStck: topic.mainTopic + '/delete_stck_by_ids',
    editStckBin: topic.mainTopic + '/edit_stck_bin',
    getStckMakesByStckId: topic.mainTopic + '/get_stck_makes_by_stck_id',
    getStckOccusByStckId: topic.mainTopic + '/get_stck_occus_by_stck_id',
    stckInByMake: topic.mainTopic + '/stck_in_by_make',
    getStckBinByStckId: topic.mainTopic + '/get_stck_bin_by_stck_id',
    stckInByProcFact: topic.mainTopic + '/stck_in_by_proc_fact',
    stckOutByProcFact: topic.mainTopic + '/stck_out_by_proc_fact',
}