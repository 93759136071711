<template>
  <div>
    <el-table ref="multiTable" :data="tableData" border v-loading="loadingFlag" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="50" align="center"></el-table-column>
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column label="类型" prop="mtrl_type" :formatter="formaMtrlType"/>
      <el-table-column label="名称" prop="mtrl_name">
        <template v-slot="scope">
          <el-link v-if="scope.row.mtrl_name" class="vg_cursor" type="primary" @click="openNameRecord(scope.row)">{{scope.row.mtrl_name}}</el-link>
          <span v-else class="vg_9f9a9a">暂无</span>
        </template>
      </el-table-column>
      <el-table-column label="库存数量" prop="stck_num" show-overflow-tooltip align="center"/>
      <el-table-column label="可用库存" prop="stck_unum" align="center">
      </el-table-column>
      <el-table-column label="占用库存" prop="stck_onum" align="center">
        <template v-slot="scope">
          <el-link v-if="scope.row.delete_time === 0" class="vg_cursor" type="primary" @click="openOccupyRecord(scope.row)">{{scope.row.stck_onum}}</el-link>
          <span v-else>{{scope.row.stck_onum}}</span>
        </template>
      </el-table-column>
      <!-- v-if="userInfo.cptt_type === 5" -->
      <el-table-column label="需制库存" prop="stck_mnum" align="center">
        <template v-slot="scope">
          <el-link v-if="scope.row.delete_time === 0" class="vg_cursor" type="primary" @click="openNeedRecord(scope.row)">{{scope.row.stck_mnum}}</el-link>
          <span v-else>{{scope.row.stck_onum}}</span>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" prop="create_time" :formatter="formatDate"/>
      <el-table-column v-if="userInfo.acct_id === 1" label="*所属账套" prop="acct_no" />
      <el-table-column v-if="userInfo.acct_id === 1" label="*工厂类型" prop="cptt_type"  :formatter="formaCpttType"/>
      <el-table-column label="均价" prop="average_price">
        <template v-slot="scope">
          <span v-if="scope.row.average_price">{{scope.row.average_price}}</span>
          <span v-else class="vg_9f9a9a">暂无</span>
        </template>
      </el-table-column>
      <el-table-column label="库位" prop="stck_bin" align="center">
        <template v-slot="scope">
          <el-link class="vg_cursor" type="primary" @click="openWareHouse(scope.row)">{{scope.row.stck_bin}}</el-link>
          <!-- <span v-else class="vg_9f9a9a">暂无</span> -->
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template v-slot="scope">
          <el-tag type="success" size="mini" v-if="scope.row.delete_time === 0">正常</el-tag>
          <el-tag type="danger" size="mini" v-else>作废</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"/>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="NameRecordFlag"
      width="70%">
			<NameRecord :mrtlName="mrtlName" :factType="0" ref="NameRecord" :AveragePrice="AveragePrice"></NameRecord>
		</el-dialog>
    <el-dialog
      :visible.sync="OccupyRecordFlag"
      width="70%">
			<OccupyRecord :mrtlName="mrtlName" ref="OccupyRecord"></OccupyRecord>
		</el-dialog>
    <el-dialog
      :visible.sync="NeedRecordFlag"
      width="70%">
			<NeedRecord @OnekeyWarehous="OnekeyWarehous" :mrtlName="mrtlName" ref="NeedRecord"></NeedRecord>
		</el-dialog>
		<WareHouse :factType="0" @cancelWareHouse="cancelWareHouse" :WareHouseFlag="WareHouseFlag" :mrtlName="mrtlName" :WareHouseNum="WareHouseNum" ref="WareHouse"></WareHouse>
  </div>
</template>

<script>
import NameRecord from "../Dialog/NameRecord.vue"
import OccupyRecord from "../Dialog/OccupyRecord.vue"
import NeedRecord from "../Dialog/NeedRecord.vue"
import WareHouse from "../Dialog/WareHouse.vue"
import pubPagination from "@/components/common/pubPagination";
export default {
  name:'WrongProcessFactory',
  props:{
    tableData:{
      type:Array,
    },
    loadingFlag:{
      type:Boolean,
    },
    userInfo:{
      type:Object
    },
    totalPage:{
      type:Number
    }
  },
  components: {
    NameRecord,
    OccupyRecord,
    NeedRecord,
    pubPagination,
    WareHouse
  },
  data(){
    return{
      selectionList:[],
      NameRecordFlag:false,
      OccupyRecordFlag:false,
      NeedRecordFlag:false,
      WareHouseFlag:false,
      mrtlName:'',
      WareHouseNum:0,
      AveragePrice:'',
    }
  },
  methods:{
    // 勾选值确认值
		handleSelectionChange(val) {
			this.selectionList = val;
      this.$emit("wrongSelectionChange",val)
		},
		// 时间转换
		formatDate({update_time}) {
			return this.helper.toStringDay(update_time)
		},
    // 回显工厂类型
    formaCpttType(val){
      switch(val.cptt_type){
        case 1:
          return '加工厂';
        case 2:
          return '面料厂';
        case 3:
          return '配套厂';
        case 4:
          return '辅料厂';
        case 5:
          return '包材厂';
      }
    },
    // 物料类型
    formaMtrlType(val){
      switch(val.mtrl_type){
        case 0:
          return '辅料';
        case 1:
          return '包材';
        case 2:
          return '成品';
        case 3:
          return '面料';
        case 4:
          return '外发半成品';
        case 5:
          return '皮壳';
      }
    },
    // 打开名称出入库
    openNameRecord(val){
      this.mrtlName = val.mtrl_name;
      this.AveragePrice = val.average_price;
      this.NameRecordFlag = true;
      setTimeout(() => {
        this.$refs.NameRecord.getList(val.stck_id)
      }, 300);
    },
    // 打开占用库存弹窗
    openOccupyRecord(val){
      this.mrtlName = val.mtrl_name;
      this.OccupyRecordFlag = true;
      setTimeout(() => {
        this.$refs.OccupyRecord.getList(val.stck_id)
      }, 300);
    },
    // 打开需制库存弹窗
    openNeedRecord(val){
      this.mrtlName = val.mtrl_name;
      this.NeedRecordFlag = true;
      setTimeout(() => {
        this.$refs.NeedRecord.getList(val.stck_id)
      }, 300);
    },
    // 打开库位详情
    openWareHouse(val){
      this.mrtlName = val.mtrl_name;
      this.WareHouseNum = val.stck_num;
      this.WareHouseFlag = true;
      setTimeout(() => {
        this.$refs.WareHouse.getList(val.stck_id)
      }, 300);
    },
    // 关闭库位详情
    cancelWareHouse(val){
      this.WareHouseFlag = false;
      if(val === 1){
        this.OnekeyWarehous()
      }
    },
    // 分页查询
		changePageSearch(val) {
      this.$emit('wrongChangePage',val)
		},
    // 置空总数 	
    empty(){
      this.$refs.pubPagination.currentPage = 1;
    },
    // 入库成功刷新
    OnekeyWarehous(){
      this.$emit('OnekeyWarehous')
    },
  }
}
</script>

<style>

</style>